:root {
    --primaryColor: #8ee4af;
    --mainWhite: #fff;
    --mainBlack: #000000;
    --darkbg: #0e1012;
    --darkgrey: #303030;
    --blue: #03085D;
    --yellow: #FFB831;
    --lightGrey: #F2F2F2;
    --lightBlue: #4C54D1;
    --grey: #D9D9D9;
    --greyText: #808080;
    --mainTransition: all 0.3s linear;
    --mainSpacing: 3px;
    --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
    --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  }

  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.2vw 2vw;
    background: transparent;
    color: var(--greyText);
    z-index: 9;
    padding-top: 0.5vw;
  }
  .nav-header {
    display: flex;
    justify-content: space-between;
  }
  
   .nav-header img{
    width: 100%;
    height: 50px;
  }
  
  .nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  .nav-icon {
    font-size: 24px;
    color: var(--blue);
  }
  .nav-links {
    height: 0;
    overflow: hidden;
    transition: var(--mainTransition);
    list-style-type: none;
    width: 100%;
    padding-top: 8px;
  }
  
  .nav-links a {
    display: block;
    text-decoration: none;
    padding: 0.5vw 0;
    color: var(--greyText);
    transition: var(--mainTransition);
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 20px;
  }

  .nav-links a:hover {
    color: var(--blue);
  }
  
  .show-nav {
    height: 350px;
  }
  
  .pre-register {
    display: none;
    font-size: 0.9vw;
  }
  
  a {
    text-decoration: none;
  }

@media screen and (min-width: 830px) {
  .nav-header img{
    width: 8vw;
    height: 3vw;
  }

  .nav-links a {
    display: block;
    text-decoration: none;
    padding: 0.5vw 0;
    color: var(--greyText);
    transition: var(--mainTransition);
    text-align: center;
    font-size: 0.9vw;
    font-weight: 100;
    margin-bottom: 10px;
  }

    .nav-btn {
        display: none;
      }
      .nav-center {
        padding: 0 6%;
        display: flex;
        overflow: hidden;
      }
      .nav-links {
        height: auto;
        display: flex;
        margin-top: 0px;
        justify-content: center;
      }
      .nav-links li {
        margin-left: 0.8vw;
        margin-right: 0.8vw;
      }
    
      .nav-links li  div {
        padding: 0.7vw 1vw; 
        margin-top: 0.4 vw;
      }
    
      .nav-links a {
        padding: 0;
      }
    
      .nav-links li .active div {
        padding: 0.7vw 1vw;
        background: linear-gradient(120deg, #4644A8 0%, #6260AB 20%,#7977A5 40%, #7B799C 60%,  #6F6C78 80%);
        color: var(--mainWhite);
        border-radius: 30vw;
        box-shadow: 0px 0px 4px 0px #cccaca;
      }
    
      .pre-register {
        margin-top: 0.4rem;
        padding: 0.7vw 1.5vw;
        background: linear-gradient(120deg, #4644A8, #6260AB ,#FBFBFB);
        color: var(--mainWhite);
        border-radius: 3vw;
        display: block;
        text-align: center;
        width: 8vw;
        box-shadow: 0px 0px 4px 0px #cccaca;
        width: max-content;
      }
    
}

@media (max-width: 1240px) {
    .pre-register {
        margin-left: 10px; 
        padding: 10px 20px;
    }
}

@media (max-width: 1000px) {
    .nav-links {
        margin-left: 10px; 
    }
    .nav-links li {
        margin-left: 0px; 
    }
}